.footer-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-color: #007acc;
  font-size: 0.625rem;
  padding: 0.3125rem; }
  .footer-bar::after {
    content: '';
    clear: both; }

.footer-bar__info,
.footer-bar__info--lg {
  margin-left: 0.625rem; }
  .footer-bar__info:first-child,
  .footer-bar__info--lg:first-child {
    margin-left: 0; }

.footer-bar__info--lg {
  display: none; }
  @media only screen and (min-width: 60em) {
    .footer-bar__info--lg {
      display: inline-block; } }

.footer-bar__left {
  margin-left: 0.75rem !important; }

.footer-bar__right {
  float: right !important;
  margin-right: 0.75rem !important; }

.icon--footer {
  font-size: 0.875rem;
  vertical-align: sub; }

.icon--footer-small {
  font-size: 0.6875rem;
  vertical-align: text-bottom; }
