@import url(https://fonts.googleapis.com/css?family=Roboto);
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  background-color: #1e1e1e;
  color: #adadad;
  line-height: 1.5;
  font-family: "Roboto", Helvetica, sans-serif;
  overflow-x: hidden; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-heavy {
  font-weight: 700; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.center {
  text-align: center; }

.justify {
  text-align: justify; }

.hidden-sm,
.hidden-md,
.hidden-lg {
  display: none; }

.show-md {
  display: block; }

.d-block {
  display: block !important; }

.d-none {
  display: none !important; }

.d-inline-block {
  display: inline-block !important; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 33.75em) {
    .container {
      width: 80%; } }
  @media only screen and (min-width: 60em) {
    .container {
      width: 75%;
      max-width: 60rem; } }

@media only screen and (min-width: 45em) {
  .hidden-sm {
    display: inline;
    display: initial; } }

@media only screen and (min-width: 60em) {
  .hidden-md {
    display: block; }
  .show-md {
    display: none; } }

@media only screen and (min-width: 72.5em) {
  .hidden-lg {
    display: inline-block; } }

.fade {
  opacity: 1;
  -webkit-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear; }

.show {
  opacity: 1; }

.hide {
  opacity: 0; }

.reset-link {
  color: inherit;
  text-decoration: none; }

.ml-spacing {
  margin-left: 0.75rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer; }

.top-bar {
  background-color: #3c3c3c;
  font-size: 0.875rem;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%; }
  .top-bar .top-bar__left,
  .top-bar .top-bar__right {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .top-bar:after {
    content: '';
    clear: both; }

.top-bar__right {
  position: absolute;
  width: calc( 100% - 132px);
  margin-left: auto;
  margin-right: auto; }

.top-bar__btn {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 50%;
  margin-left: 0.25rem;
  border: 0;
  font-size: 0.625rem;
  padding-left: 0.0625rem;
  padding-top: 0.0625rem; }
  @media only screen and (min-width: 45em) {
    .top-bar__btn {
      margin-left: 0.75rem; } }
  .top-bar__btn:first-child {
    margin-left: 0; }
  .top-bar__btn:focus {
    outline: 0; }
  .top-bar__btn:hover {
    color: #1e1e1e; }

.top-bar__btn--close {
  color: #ff5c5c;
  background-color: #ff5c5c;
  border: 1px solid #ff4343; }
  .top-bar__btn--close:active {
    background: #c14645;
    border: 1px solid #b13c3b; }

.top-bar__btn--minimize {
  color: #ffbd4c;
  background-color: #ffbd4c;
  border: 1px solid #ffb433; }
  .top-bar__btn--minimize:active {
    background: #c08e38;
    border: 1px solid #ac7f32; }

.top-bar__btn--expand {
  color: #00ca56;
  background-color: #00ca56;
  border: 1px solid #00b14b; }
  .top-bar__btn--expand:active {
    background: #029740;
    border: 1px solid #027e35; }

@media only screen and (min-width: 60em) {
  .middle-bar {
    flex-grow: 1;
    display: flex; } }

.middle-bar:after {
  content: '';
  clear: both; }

.middle-bar__left-panel {
  margin: 0 !important;
  background-color: #252526;
  display: flex;
  align-items: stretch; }

.middle-bar__right-panel {
  margin: 0 !important;
  width: 100%;
  background-color: #1e1e1e;
  overflow: scroll; }
  @media only screen and (min-width: 60em) {
    .middle-bar__right-panel {
      position: absolute;
      left: 270px; } }

.middle-bar__left-panel__title {
  height: 0.75rem;
  padding: 0.625rem 0 0.625rem 0.75rem;
  margin-bottom: 0.625rem;
  font-size: 0.625rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2); }
  .middle-bar__left-panel__title:after {
    clear: both;
    content: ''; }

.middle-bar__left-panel__files {
  font-size: 0.875rem; }

.middle-bar__left-panel__options {
  padding-right: 0.75rem; }

.options__icons {
  margin-left: 0.75rem; }
  .options__icons:hover {
    color: #fff; }

.middle-bar__left-column {
  display: none;
  background-color: #333333;
  padding: 0.375rem;
  width: 1.875rem;
  font-size: 1.5625rem; }
  @media only screen and (min-width: 60em) {
    .middle-bar__left-column {
      display: block;
      position: fixed;
      height: 100vh;
      top: 1.8125rem; } }

.middle-bar__explorer {
  margin-top: 30px;
  width: 100%;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.125rem; }
  @media only screen and (min-width: 60em) {
    .middle-bar__explorer {
      margin-top: 0;
      width: 14.375rem;
      box-shadow: none;
      margin-bottom: 0;
      position: fixed;
      top: 1.625rem;
      left: 2.625rem; } }

.seti-icon {
  width: 0.75rem;
  height: 0.75rem; }

.seti-icon--small {
  padding-left: 0.3125rem;
  padding-right: 0.1875rem; }

.seti-icon--big {
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle; }

.folder--in {
  padding-left: 1.5rem !important; }

.file {
  padding-left: 0.75rem; }
  .file:hover {
    background-color: #3f3f46; }

.folder {
  padding-left: 0.5rem; }
  .folder .seti-icon {
    vertical-align: middle; }
  .folder:hover {
    background-color: #3f3f46;
    cursor: pointer; }

.tabs {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0;
  width: 100%;
  background-color: #252526;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2); }
  @media only screen and (min-width: 60em) {
    .tabs {
      position: fixed;
      top: 1.8125rem;
      width: calc(100% - 271px); } }

.tab {
  padding: 0.25rem 0.75rem 0.25rem 0.625rem;
  border-right: 1px solid #1e1e1e;
  background-color: #2d2d2d; }
  .tab svg {
    color: #2d2d2d; }
  .tab:hover svg {
    color: #fff; }

.icon--tab {
  vertical-align: bottom; }

.tab--active {
  color: #fff;
  background-color: #1e1e1e; }
  .tab--active svg {
    color: #fff; }

.icon--explorer {
  width: 1.625rem;
  height: 1.625rem;
  fill: #adadad;
  cursor: default;
  margin-bottom: 0.3125rem; }

.icon--explorer-pdf {
  fill: #ff5c5c; }

.icon--explorer-title {
  width: 0.75rem;
  height: 0.75rem;
  fill: #adadad; }

.icon--active {
  fill: #fff;
  cursor: pointer; }

.file-content {
  overflow-y: scroll; }
  @media only screen and (min-width: 60em) {
    .file-content {
      margin-top: 3.4375rem; } }
  .file-content pre {
    padding-top: 0.125rem !important; }
  .file-content code:first-child {
    text-align: right !important; }

.explorer--active {
  background-color: #094771;
  color: #fff; }

.file--link {
  text-decoration: none;
  color: inherit;
  display: block;
  font-size: 0.875rem; }
  .file--link:hover, .file--link:focus, .file--link:active {
    text-decoration: none;
    color: inherit; }

.folder--open:before,
.explorer--open:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3125rem;
  vertical-align: middle;
  content: '';
  border-bottom: 5px solid;
  border-right: 0px solid transparent;
  border-left: 5px solid transparent; }

.explorer--open:before {
  margin-left: 0;
  margin-right: 0.1875rem; }

.folder--close:before,
.explorer--close:before {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid; }

.pdf-link svg {
  margin-top: 1rem;
  width: 1.875rem;
  height: 1.875rem; }
  .pdf-link svg:hover {
    fill: #fff; }

.btn--folder {
  width: 100%;
  text-align: left; }

.footer-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-color: #007acc;
  font-size: 0.625rem;
  padding: 0.3125rem; }
  .footer-bar::after {
    content: '';
    clear: both; }

.footer-bar__info,
.footer-bar__info--lg {
  margin-left: 0.625rem; }
  .footer-bar__info:first-child,
  .footer-bar__info--lg:first-child {
    margin-left: 0; }

.footer-bar__info--lg {
  display: none; }
  @media only screen and (min-width: 60em) {
    .footer-bar__info--lg {
      display: inline-block; } }

.footer-bar__left {
  margin-left: 0.75rem !important; }

.footer-bar__right {
  float: right !important;
  margin-right: 0.75rem !important; }

.icon--footer {
  font-size: 0.875rem;
  vertical-align: sub; }

.icon--footer-small {
  font-size: 0.6875rem;
  vertical-align: text-bottom; }

.editor {
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  height: 100vh; }

.alternative {
  height: 100vh;
  background-color: #1e1e1e; }
  .alternative h2:first-child {
    margin-top: 0;
    padding-top: 1.5rem; }

.alternative--link {
  color: inherit;
  font-weight: 700;
  text-decoration: underline;
  font-size: 1.5rem; }
  .alternative--link:hover {
    text-decoration: none; }

.btn--fake {
  border: 1px solid #adadad;
  padding: 0.5rem;
  font-size: 1.875rem; }

.no-match__body {
  height: 100vh;
  background: #0000aa;
  color: #fff;
  font-family: courier;
  font-size: 1.125rem;
  text-align: center;
  padding-top: 0.9375rem; }

.blink {
  color: yellow;
  -webkit-animation: blink 1s step-end infinite;
          animation: blink 1s step-end infinite; }

@-webkit-keyframes blink {
  67% {
    opacity: 0; } }

@keyframes blink {
  67% {
    opacity: 0; } }

.no-match__neg {
  background: #fff;
  color: #0000aa;
  padding: 0.125rem 0.5rem;
  font-weight: bold;
  font-family: courier; }

.no-match__menu {
  text-align: center;
  margin-top: 3.125rem;
  font-family: courier; }
  .no-match__menu a,
  .no-match__menu a:hover {
    color: inherit;
    font-family: courier; }

.no-match__text {
  margin-left: 6.25rem;
  font-family: courier; }

