@import url("https://fonts.googleapis.com/css?family=Roboto");
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  background-color: #1e1e1e;
  color: #adadad;
  line-height: 1.5;
  font-family: "Roboto", Helvetica, sans-serif;
  overflow-x: hidden; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-heavy {
  font-weight: 700; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.center {
  text-align: center; }

.justify {
  text-align: justify; }

.hidden-sm,
.hidden-md,
.hidden-lg {
  display: none; }

.show-md {
  display: block; }

.d-block {
  display: block !important; }

.d-none {
  display: none !important; }

.d-inline-block {
  display: inline-block !important; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 33.75em) {
    .container {
      width: 80%; } }
  @media only screen and (min-width: 60em) {
    .container {
      width: 75%;
      max-width: 60rem; } }

@media only screen and (min-width: 45em) {
  .hidden-sm {
    display: initial; } }

@media only screen and (min-width: 60em) {
  .hidden-md {
    display: block; }
  .show-md {
    display: none; } }

@media only screen and (min-width: 72.5em) {
  .hidden-lg {
    display: inline-block; } }

.fade {
  opacity: 1;
  transition: opacity 0.25s linear; }

.show {
  opacity: 1; }

.hide {
  opacity: 0; }

.reset-link {
  color: inherit;
  text-decoration: none; }

.ml-spacing {
  margin-left: 0.75rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  cursor: pointer; }
