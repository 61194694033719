.top-bar {
  background-color: #3c3c3c;
  font-size: 0.875rem;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%; }
  .top-bar .top-bar__left,
  .top-bar .top-bar__right {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .top-bar:after {
    content: '';
    clear: both; }

.top-bar__right {
  position: absolute;
  width: calc( 100% - 132px);
  margin-left: auto;
  margin-right: auto; }

.top-bar__btn {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 50%;
  margin-left: 0.25rem;
  border: 0;
  font-size: 0.625rem;
  padding-left: 0.0625rem;
  padding-top: 0.0625rem; }
  @media only screen and (min-width: 45em) {
    .top-bar__btn {
      margin-left: 0.75rem; } }
  .top-bar__btn:first-child {
    margin-left: 0; }
  .top-bar__btn:focus {
    outline: 0; }
  .top-bar__btn:hover {
    color: #1e1e1e; }

.top-bar__btn--close {
  color: #ff5c5c;
  background-color: #ff5c5c;
  border: 1px solid #ff4343; }
  .top-bar__btn--close:active {
    background: #c14645;
    border: 1px solid #b13c3b; }

.top-bar__btn--minimize {
  color: #ffbd4c;
  background-color: #ffbd4c;
  border: 1px solid #ffb433; }
  .top-bar__btn--minimize:active {
    background: #c08e38;
    border: 1px solid #ac7f32; }

.top-bar__btn--expand {
  color: #00ca56;
  background-color: #00ca56;
  border: 1px solid #00b14b; }
  .top-bar__btn--expand:active {
    background: #029740;
    border: 1px solid #027e35; }
